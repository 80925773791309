import React, {useEffect} from 'react';
// import { motion, useAnimation } from 'framer-motion';
import db from '../assets/DANBABA PNG-1.png'
// import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {

  useEffect(() => {
    AOS.init();
  }, []); 
  
  return (
    <section id='#about' className="lg:flex lg:flex-row flex-col items-center justify-between w-[90%] mx-auto py-[90px]">
        <div 
        className="w-full lg:w-[50%]"
        data-aos="fade-right" data-aos-duration="1000"
      > 
        <h2 className="font-bold font-body font-mont text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-6 mt-6 md:mb-8 ">Who We Are</h2>
        <p className=" font-body font-lato text-base md:text-md lg:text-lg xl:text-xl leading-6 md:leading-7 lg:leading-8 xl:leading-9 " style={{textAlign: 'justify'}}>
         Danbaba group is not just a company or a business, it is a movement, a cause, a corporate rebellion that seeks to address and solve the menace of illitracy,
         laziness, lethargy, poor leadership and political mishaps and unemployment in Nigeria.
         Danbaba is an assembly of various components and sectors of the construction industry with a view to harness and harmonize the various energies in each sector;
         the concept is coined from <span className='font-semibold'>"the law of conservation of energy"</span> which states that energy can neither be created nor destroyed but can be 
         transformed from one from to another.
        </p>
        
      </div>
      <div 
        className="lg:w-[40%] w-full mt-5 rounded-10"
        data-aos="fade-left" data-aos-duration="1000"
      >
        <img 
          src={ db }
          alt='danbaba-grp'
          className="rounded-10 mx-auto w-full object-cover"
        />
      </div>
    
    </section>
  )
}

export default About