import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
// import amdan from './AMDAN LOGO_041927.png';
import image1 from './PHOTO-2024-04-26-05-57-02.jpg'
import image2 from './PHOTO-2024-04-26-05-57-06.jpg'
import image3 from './PHOTO-2024-04-26-05-57-09.jpg'
import Image4 from './IMG_4673.jpg';
import Image5 from './IMG_4657.jpg';


const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '450px',
  position: 'relative',
};

// const contentStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   textAlign: 'center',
// };

const slideImages = [
  {
    url: image1,
    // content: (
    //   <div style={contentStyle} data-aos="fade-in" data-aos-duration="1000">
    //     <div className='w-full max-w-md p-4 rounded-lg text-white'>
    //       <img className='w-20 mx-auto mt-[-3rem]' src={amdan} alt="amdan academy logo" />
    //       <h2 className='text-2xl font-bold py-2'>Amdan academy</h2>
    //       <div className='text-center font-medium'>
    //         <p style={{textAlign: 'justify'}}>
    //          Amdan academy we train people for careers in the construction industry.
    //          Their programs cover a wide range of skills and knowledge necessary for various roles, providing students with practical training
    //          and theoretical understanding to excel in their careers.
    //         </p>
    //       </div>
    //     </div>
    //   </div>  
    // ),

    
  },
 
 { url: image3,
  // content: (
  //   <div style={contentStyle} data-aos="fade-in" data-aos-duration="1000">
  //     <div className='w-full max-w-md p-4 rounded-lg text-white'>
  //       <img className='w-20 mx-auto mt-[-3rem]' src='' alt="/" />
  //       <h2 className='text-2xl font-bold py-2'>Sapphire</h2>
  //       <div className='text-center font-medium'>
  //         <p style={{textAlign: 'justify'}}>
  //          Join our pioneering team for precision craftmanship in carpenrty, bricklaying and more.
  //         </p>
  //       </div>
  //     </div>
  //   </div>
    
  // ),
},

  { url: image2,
// content: (
//   <div style={contentStyle} data-aos="fade-in" data-aos-duration="1000">
//     <div className='w-full max-w-md p-4 rounded-lg text-white'>
//       <img className='w-20 mx-auto mt-[-3rem]' src='' alt="/" />
//       <h2 className='text-2xl font-bold py-2'>Amdan Realtors</h2>
//       <div className='text-center font-medium'>
//         <p>
//           We build and integrate Application programming 
//           interfaces
//         </p>
//       </div>
//     </div>
//   </div>
  
// ),
},
{ url: Image4,
// content: (
//   <div style={contentStyle} data-aos="fade-in" data-aos-duration="1000">
//     <div className='w-full max-w-md p-4 rounded-lg text-white'>
//       <img className='w-20 mx-auto mt-[-3rem]' src='' alt="/" />
//       <h2 className='text-2xl font-bold py-2'>Danbaba luxury interiors</h2>
//       <div className='text-center font-medium'>
//         <p>
//           We build and integrate Application programming 
//           interfaces
//         </p>
//       </div>
//     </div>
//   </div>
  
// ),
},
{
  url: Image5
}


// Add other slide objects similarly
];

const Slideshow = () => {
  return (
    <section id='#companies' className="w-[90%]  lg:w-[90%] mx-auto py-5 lg:py-16 ">
      <div className="slide-container" data-aos="fade-right" data-aos-duration="1000">
        <Slide>
          {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                {slideImage.content}
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    </section>
  );
};

export default Slideshow;
