import React from 'react'
 import Agro from "../assets/amdan.png"
 import Real from "../assets/luxury.png"
 import Mine from "../assets/realtors.png"
import asset from '../assets/saph.png'
const Companies = () => {
  return (
    <div id='chooseus' className='text-center pt-20 mb-10' data-aos="fade-in" data-aos-duration="1000">
         <h1 className=' '>Our Companies </h1>
         <h2 className='text-3xl'>Across Nigeria</h2>
      <div className='w-full py-[6rem] px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-16'>
             <div className='w-full flex-col p-2  hover:scale-105 duration-300 rounded-lg '>
             <img className='w-50 mx-auto mt-[-3rem]' src={Agro}alt="/" />
                <h2 className='text-xl font-bold text-center py-5'>Amdan Academy</h2>
                <div className='text-center font-medium'>
                        <p className='text-justify'>
                        This company basically focuses on design and construction. It is saddled with the 
responsibility of sourcing projects, design and construction of the building form 
only. It is responsible for designing building edifice that are in harmony with built 
environment.
                        </p>
                </div>
            </div>
            <div className='w-full  hover:scale-105 duration-300 flex-col p-2 rounded-lg'>
             <img className='w-45 mx-auto mt-[-3rem]' src={Real} alt="/" />
                <h2 className='text-xl font-bold text-center py-5'>Danbaba Luxury</h2>
                <div className=' font-medium text-center'>
                        <p className='text-justify'>
                        Luxury is in each detail (Hubert de Givenchy). Space is money; from the seamless 
flowing spaces, to the interactive building performance using state of the art 
artificial intelligence which gives total control and also brings about flexibility and 
fluidity.
                        </p>
                </div>
            </div>
            <div className='w-full  hover:scale-105 duration-300 flex-col p-2 rounded-lg'>
             <img className='w-50 mx-auto mt-[-3rem]' src={Mine} alt="/" />
                <h2 className='text-xl font-bold text-center py-5'>Amdan Realtors</h2>
                <div className='text-center font-medium'>
                        <p className='text-justify'>
                        Real estate cannot be lost, stolen nor can it be carried away. Purchased with 
common sense, paid for in full and managed with reasonable care, it is about the 
safest investment in world (Franklin D. Roosevelt). 
Amdan Realtors is the final branding stage of the whole process.  </p>
                </div>
            </div>
            <div className='w-full  hover:scale-105 duration-300 flex-col p-2 rounded-lg'>
             <img className='w-50 mx-auto mt-[-3rem]' src={asset} alt="/" />
                <h2 className='text-xl font-bold text-center py-5'>Saphire</h2>
                <div className='text-center font-medium'>
                        <p className='text-justify'>
                        The end of joblessness is here; the dawn of a new era in 21st century employment 
brouhaha. Where the idealist meets the realist; this synergy is complemented by 
the emergence of the contemporary artisan who operates with high level of 
digital literacy and craftsmanship.
                        </p>
                </div>
            </div>
        </div>
      </div>
    </div>

  )
}

export default Companies
