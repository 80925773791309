import React, { useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const Countpage = () => {
    const [counterState, setCounterState] = useState(false)
  return (
    <div className=' pt--40'>
        <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
        <div className=' items-center grid md:grid-cols-4 text-center px-20 pt-20 pb-20'>
        <div data-aos="fade-right" data-aos-duration="1000">
            <h2 className='text-6xl font-bold'>
                { counterState &&
                <CountUp start={0} end={500} duration={1.00}></CountUp>
                }
                </h2>
            <p className='py-2 font-medium'>Employees</p>
        </div>

        <div data-aos="fade-left" data-aos-duration="1000">
        <h2 className='text-6xl font-bold'>
            { counterState &&
        <CountUp start={0} end={200} duration={1.00}></CountUp>
            }
            </h2>
            <p className='py-2 font-medium'>Completed projects</p>

        </div>

        <div data-aos="fade-right" data-aos-duration="1000">
        <h2 className='text-6xl font-bold'>
            { counterState &&
        <CountUp start={0} end={1000} duration={1.00}></CountUp>
            }
            +</h2>
            <p className='py-2 font-medium'>Happy clients</p>
        </div>

        <div data-aos="fade-leftt" data-aos-duration="1000">
        <h2 className='text-6xl font-bold'>
            { counterState &&
        <CountUp start={0} end={150} duration={1.00}></CountUp>
            }
            hrs</h2>
            <p className='py-2 font-medium'>Customer service</p>

        </div>
      </div>
            </ScrollTrigger>   
     
    </div>
  )
}

export default Countpage
