import React, {useState, useEffect} from 'react'
import Navbar from './components/navbar'
import Welcome from './components/welcome'
import About from './components/aboutus'
import Preloader from './components/loader/preloader'
import Footer from './components/footer'
import Slideshow from './components/carousel/carousel'
import Countpage from './components/countpage'
import Companies from './components/companies'




function App() {
  useEffect(() => {
    document.title = 'Danbaba group'
  }, []);
const [loading, setLoading] = useState(false)
useEffect(() => {
  setLoading(true)
  setTimeout(() => {
    setLoading(false)
  }, 5000)
}, {})
  return (
   <div>
    {
      loading?
     <Preloader/>
      :
      <div>
        <Navbar/>
        <Welcome/>
        <About/>
        <Companies/>
        <Slideshow/>
        <Countpage/>
        <Footer/>
      </div>
    }

   </div>
  );
}

export default App;
